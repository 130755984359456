<template>
    <div>
        <!-- 主体 -->
        <LiefengContent>
            <template v-slot:title>{{ "微信群管理" }}</template>
            <!-- 头部搜索层 -->
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search">
                    <FormItem>
                        <Input v-model="searchForm.groupName" maxlength="50" placeholder="微信群名称" style="width: 160px"></Input>
                    </FormItem>
                    <FormItem>
                        <Input v-model="searchForm.weixinId" maxlength="50" placeholder="微信群ID" style="width: 160px"></Input>
                    </FormItem>
                    <FormItem>
                        <CheckboxGroup v-model="searchForm.groupId">
                            <Checkbox label="-1">未关联畅联组</Checkbox>
                        </CheckboxGroup>
                    </FormItem>
                    <Button style="margin-right: 10px" type="primary" icon="ios-search" @click="searchBtn">查询</Button>
                    <Button style="margin-right: 10px" type="success" @click="resetBtn" icon="ios-refresh">重置</Button>
                </Form>
            </template>
            <!-- 分页层 -->
            <template v-slot:contentArea>
                <!-- 分页table -->
                <LiefengTable
                    :talbeColumns="pageForm.talbeColumns"
                    :tableData="pageForm.tableData"
                    :loading="pageForm.loading"
                    :fixTable="true"
                    :curPage="pageForm.page"
                    :total="pageForm.total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageForm.pageSize"
                    @hadlePageSize="changeTableSize"
                ></LiefengTable>

                <LiefengModal :title="'修改群'" :value="addChangeStatus" @input="addChangetatusFn">
                    <template v-slot:contentarea>
                        <Form>
                            <FormItem>
                                <span slot="label" class="validate">微信群名称</span>
                                <Input v-model.trim="addChangeData.groupName" placeholder="请输入微信群名称"></Input>
                            </FormItem>
                            <FormItem label="微信群人数">
                                <span slot="label" class="validate">微信群人数</span>
                                <Input v-model="addChangeData.memberNum" type="number" placeholder="请输入微信群人数" />
                            </FormItem>
                        </Form>
                    </template>
                    <template v-slot:toolsbar>
                        <Button type="info" @click="addChangetatusFn(false)" style="margin-right: 10px">取消</Button>
                        <Button type="primary" @click="saveAddChangeFn">保存</Button>
                    </template>
                </LiefengModal>
            </template>
            <!-- 新增modal -->
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"

export default {
    components: { LiefengContent, LiefengTable, LiefengModal },
    data() {
        return {
            //分页参数
            pageForm: {
                talbeColumns: [
                    {
                        title: "微信群名称",
                        key: "groupName",
                        minWidth: 200,
                        align: "center",
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            this.addChangeData = {
                                                groupName: params.row.groupName,
                                                memberNum: params.row.memberNum,
                                                weixinId: params.row.weixinId,
                                            }
                                            this.addChangetatusFn(true)
                                        },
                                    },
                                },
                                params.row.groupName
                            )
                        },
                    },
                    {
                        title: "微信群ID",
                        key: "weixinId",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "微信群人数",
                        key: "memberNum",
                        minWidth: 200,
                        align: "center",
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            this.addChangeData = {
                                                groupName: params.row.groupName,
                                                memberNum: params.row.memberNum,
                                                weixinId: params.row.weixinId,
                                            }
                                            this.addChangetatusFn(true)
                                        },
                                    },
                                },
                                params.row.memberNum
                            )
                        },
                    },
                    {
                        title: "已激活人数",
                        key: "memberRealNum",
                        minWidth: 200,
                        align: "center",
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    },
                                    on: {
                                        click: async () => {
                                            this.$core.openLayerFrame({
                                                type: 2,
                                                title: "群成员管理",
                                                content: `/page#/groupcontacter?groupId=${params.row.groupId}&orgCode=${params.row.communityCode}&wechat=true`,
                                                area: ["100%", "100%"],
                                            })
                                        },
                                    },
                                },
                                params.row.memberRealNum
                            )
                        },
                    },
                    {
                        title: "提交账号",
                        key: "creatorAccount",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "群所属机构",
                        key: "orgName",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "关联畅联组",
                        key: "linkGroupName",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "提交时间",
                        key: "gmtCreate",
                        minWidth: 200,
                        align: "center",
                        render: (h, params) => {
                            return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd") : "")
                        },
                    },
                    {
                        title: "操作",
                        align: "center",
                        fixed: "right",
                        width: 300,
                        render: (h, params) => {
                            return h("div", [
                                h(
                                    "Button",
                                    {
                                        style: {
                                            marginRight: "20px",
                                            display: params.row.groupId && params.row.groupId != "" ? "" : "none",
                                        },
                                        attrs: {
                                            type: "info",
                                            size: "small",
                                        },
                                        on: {
                                            click: () => {
                                                this.$core.openLayerFrame({
                                                    type: 2,
                                                    title: "群成员管理",
                                                    content: `/page#/groupcontacter?groupId=${params.row.groupId}&orgCode=${params.row.communityCode}&wechat=true`,
                                                    area: ["100%", "100%"],
                                                })
                                            },
                                        },
                                    },
                                    "群成员管理" //1001
                                ),
                                h(
                                    "Button",
                                    {
                                        style: {
                                            marginRight: "20px",
                                            display: params.row.groupId && params.row.groupId != "" ? "" : "none",
                                        },
                                        attrs: {
                                            type: "info",
                                            size: "small",
                                        },
                                        on: {
                                            click: () => {
                                                this.$core.openLayerFrame({
                                                    type: 2,
                                                    title: `${params.row.groupName}`,
                                                    content: `/page#/groupdetail?groupId=${params.row.groupId}&groupName=${params.row.groupName}`,
                                                    area: ["100%", "100%"],
                                                })
                                            },
                                        },
                                    },
                                    "消息详情" //1001
                                ),
                                h(
                                    "Button",
                                    {
                                        attrs: {
                                            type: "info",
                                            size: "small",
                                        },
                                        on: {
                                            click: () => {
                                                this.$Modal.confirm({
                                                    title: "温馨提示",
                                                    content: "您正在删除此信息，是否继续",
                                                    onOk: () => {
                                                        this.$post("/gateway/chat/app/chat/weixinGroup/delete", {
                                                            weixinId: params.row.weixinId,
                                                        }).then(res => {
                                                            if (res && res.code == 200) {
                                                                this.$Message.success({
                                                                    content: "删除成功",
                                                                    background: true,
                                                                })
                                                                this.selectPage()
                                                                return
                                                            }
                                                        })
                                                    },
                                                })
                                            },
                                        },
                                    },
                                    "删除" //1001
                                ),
                            ])
                        },
                    },
                ],
                tableData: [],
                loading: false,
                page: 1,
                pageSize: 20,
                total: 0,
                currentPage: 0,
            },
            //查询参数
            searchForm: {
                groupName: "",
                weixinId: "",
                groupId: "",
            },

            addChangeStatus: false,
            addChangeData: {
                groupName: "",
                memberNum: "",
            },
        }
    },
    //create函数,刚加载页面时调用
    async created() {
        this.selectPage()
    },
    methods: {
        saveAddChangeFn() {
            if (!this.addChangeData.groupName || this.addChangeData.groupName == "") {
                this.$Message.warning({
                    content: "微信群名称不能为空",
                    background: true,
                })
                return
            }
            if (!this.addChangeData.memberNum || this.addChangeData.memberNum == "" || this.addChangeData.memberNum == 0) {
                this.$Message.warning({
                    content: "微信群人数不能为空或者为0",
                    background: true,
                })
                return
            }
            this.$post(
                "/gateway/chat/app/chat/weixinGroup/update",
                {
                    ...this.addChangeData,
                },
                {
                    "content-type": "application/json",
                }
            ).then(res => {
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "修改成功",
                        background: true,
                    })
                    this.addChangetatusFn(false)
                    this.selectPage()
                    return
                } else {
                    this.$Message.error({
                        content: "修改失败",
                        background: true,
                    })
                    return
                }
            })
        },
        addChangetatusFn(status) {
            this.addChangeStatus = status
        },
        /*----- 分页事件部分 -------*/

        //分页事件改变
        changeTableSize(val) {
            this.pageForm.page = val.page
            this.pageForm.pageSize = val.pageSize
            this.selectPage()
        },
        //查询按钮
        searchBtn() {
            this.pageForm.page = 1
            this.selectPage()
        },
        //重置按钮
        resetBtn() {
            this.searchForm = {
                groupName: "",
                weixinId: "",
                groupId: "",
            }
            this.pageForm.page = 1
            this.selectPage()
        },
        // 方法部分
        /*----- 接口部分 -------*/
        async selectPage() {
            this.pageForm.loading = true
            await this.$get("/gateway/chat/app/chat/weixinGroup/selectGroupPage", {
                weixinId: this.searchForm.weixinId,
                groupName: this.searchForm.groupName,
                page: this.pageForm.page,
                pageSize: this.pageForm.pageSize,
                groupId: this.searchForm.groupId ? -1 : "",
                orgCode: window.sessionStorage.getItem("orgCode") || "",
            }).then(res => {
                this.pageForm.tableData = []
                this.pageForm.loading = false
                if (res.code == 200) {
                    this.pageForm.tableData = res.dataList
                    this.pageForm.total = res.maxCount
                    this.pageForm.currentPage = res.currentPage
                } else {
                    this.$Message.error({
                        content: "获取列表失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
.none-modal {
    /deep/.ivu-modal-close {
        display: none;
    }
}
/deep/.ivu-form-item-content {
    display: flex;
}
</style>